import React, { createContext, useReducer } from 'react';
const LoginDetails = () => (localStorage.getItem("loginDetails") === null) ? { defaultLanguage: "EN" } : JSON.parse(localStorage.getItem("loginDetails"));
const initialContext = LoginDetails();
export const GlobalContext = createContext(initialContext);
export const GlobalProvider = ({ children }) => {
    const [GS, dispatch] = useReducer(AppReducer, initialContext);
    const doLogin = async (GS) => await dispatch({ type: "DO_LOGIN", payload: GS });
    const doLogout = GS => dispatch({ type: "DO_LOGOUT", payload: GS });
    const updateGS = GS => dispatch({ type: "UPDATE_GLOBAL_STATE", payload: GS });
    const updateUserSettings = Settings => dispatch({ type: "UPDATE_USER_SETTINGS", payload: Settings });
    const CheckAsyncResult = (e) => e.error && updateGS({ defaultLanguage: GS.defaultLanguage, "email": GS.userSettings === undefined ? "GoodTry@TryAgain.Later" : GS.userSettings.userDetails.u_email, connectionProblem: true });
    return <GlobalContext.Provider value={{ GS, updateGS, doLogin, doLogout, updateUserSettings, CheckAsyncResult }}>{children}</GlobalContext.Provider>;
}
const AppReducer = (state, action) => {
    switch (action.type) {
        case 'DO_LOGIN':
            state = { ...state, ...action.payload }
            localStorage.setItem("loginDetails", JSON.stringify(state));
            return state;
        case 'DO_LOGOUT':
            localStorage.removeItem("loginDetails");
            state = { defaultLanguage: "EN" }
            return state;
        case 'UPDATE_GLOBAL_STATE':
            state = { ...state.GS, ...action.payload }
            localStorage.setItem("loginDetails", JSON.stringify(action.payload));
            return state;
        case 'UPDATE_USER_SETTINGS':
            state = { ...state, userSettings: action.payload }
            localStorage.setItem("loginDetails", JSON.stringify(state));
            return state;
        default: return state;
    }
}