import React from 'react';

const Loading = () => <div className="row">
    <div className="col-12 p-0 text-center">
        <img src={require(`./../images/loading.gif`)} className="img-fluid mt-3" alt="its loading"></img>
    </div>
</div>

const BatchStatus = ["Not Started", "In progress", "Completed"];
const QuestionTypes = ["Zodiac", "House", "Planet"];
const QuestionSetStatus = ["Not ready", "Ready"];
const CourseStatus = ["Inactive", "Active", "Upcoming"];
const StudentStatus = ["Not paid", "Paid"];

const PriceList = [2000, 1000, 500]

const AccountStatusList = [
    { "id": 1, "value": "Active" },
    { "id": 0, "value": "Not active" },
    { "id": 2, "value": "Locked" },
    { "id": 3, "value": "First Login" },
]
const PaymentTypes = [
    { "id": 0, "value": "No payment" },
    { "id": 1, "value": "Partial payment" },
    { "id": 2, "value": "Full payment" },
    { "id": 3, "value": "Refund payment" },
]
const AccountTypeList = [
    { "id": 0, "value": "Admin" },
    { "id": 1, "value": "Professional (individual)" },
    { "id": 2, "value": "Student" },
    { "id": 3, "value": "Personal" },
    { "id": 4, "value": "Staff" },
    { "id": 5, "value": "Personal Plus" },
    { "id": 6, "value": "Teacher" }
]
const UpdateReason = [
    [1, "Password change"],
    [2, "Email change"],
    [3, "Account status change"],
    [4, "Account type change"],
    [5, "Chart limit change"],
    [6, "Phone change"],
    [7, "Bio change"],
    [8, "Active date changed"]
];
const ZodiacPlanetOwnerMap = { "1": 4, "2": 5, "3": 3, "4": 2, "5": 1, "6": 3, "7": 5, "8": 4, "9": 6, "10": 7, "11": 7, "12": 6 }
const NepaliMonthList = [
    { "id": 1, "name": "बैशाख" },
    { "id": 2, "name": "जेठ" },
    { "id": 3, "name": "असार" },
    { "id": 4, "name": "श्रावण" },
    { "id": 5, "name": "भाद्र" },
    { "id": 6, "name": "आश्विन" },
    { "id": 7, "name": "कार्तिक" },
    { "id": 8, "name": "मंसिर" },
    { "id": 9, "name": "पौष" },
    { "id": 10, "name": "माघ" },
    { "id": 11, "name": "फाल्गुन" },
    { "id": 12, "name": "चैत्र" },
]
const ListOfTimeZone = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Nelson',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    ' America/Knox_IN',
    ' America/Kralendijk',
    ' America/La_Paz',
    ' America/Lima',
    ' America/Los_Angeles',
    ' America/Louisville',
    ' America/Lower_Princes',
    ' America/Maceio',
    ' America/Managua',
    ' America/Manaus',
    ' America/Marigot',
    ' America/Martinique',
    ' America/Matamoros',
    ' America/Mazatlan',
    ' America/Mendoza',
    ' America/Menominee',
    ' America/Merida',
    ' America/Metlakatla',
    ' America/Mexico_City',
    ' America/Miquelon',
    ' America/Moncton',
    ' America/Monterrey',
    ' America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/ACT',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Brazil/Acre',
    'Brazil/DeNoronha',
    'Brazil/East',
    'Brazil/West',
    'CET',
    'CST6CDT',
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'Chile/Continental',
    'Chile/EasterIsland',
    'Cuba',
    'EET',
    'EST',
    'EST5EDT',
    'Egypt',
    'Eire',
    'Etc/GMT',
    'Etc/GMT+0',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-0',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/GMT0',
    'Etc/Greenwich',
    'Etc/UCT',
    'Etc/UTC',
    'Etc/Universal',
    'Etc/Zulu',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'GB',
    'GB-Eire',
    'GMT',
    'GMT+0',
    'GMT-0',
    'GMT0',
    'Greenwich',
    'HST',
    'Hongkong',
    'Iceland',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Iran',
    'Israel',
    'Jamaica',
    'Japan',
    'Kwajalein',
    'Libya',
    'MET',
    'MST',
    'MST7MDT',
    'Mexico/BajaNorte',
    'Mexico/BajaSur',
    'Mexico/General',
    'NZ',
    'NZ-CHAT',
    'Navajo',
    'PRC',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'Poland',
    'Portugal',
    'ROC',
    'ROK',
    'Singapore',
    'Turkey',
    'UCT',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Pacific-New',
    'US/Samoa',
    'UTC',
    'Universal',
    'W-SU',
    'WET',
    'Zulu',
]
const NakshatraPadList = [
    1, 2, 3, 4
]
const ZodiacList = [
    { "id": 1 },
    { "id": 2 },
    { "id": 3 },
    { "id": 4 },
    { "id": 5 },
    { "id": 6 },
    { "id": 7 },
    { "id": 8 },
    { "id": 9 },
    { "id": 10 },
    { "id": 11 },
    { "id": 12 }
]
const PlanetAspectTable = [
    { "id": 1, "aspect": [7] },
    { "id": 2, "aspect": [7] },
    { "id": 3, "aspect": [7] },
    { "id": 4, "aspect": [4, 7, 8] },
    { "id": 5, "aspect": [7] },
    { "id": 6, "aspect": [5, 7, 9] },
    { "id": 7, "aspect": [3, 7, 10] },
    { "id": 8, "aspect": [5, 7, 9] },
    { "id": 9, "aspect": [5, 7, 9] }
]
const PlanetExaltDeblitation = [
    [
        1, {
            1: 1,
            2: 7
        }
    ],
    [
        2, {
            1: 2,
            2: 8
        }
    ],
    [
        3, {
            1: 6,
            2: 12
        }
    ],
    [
        4, {
            1: 10,
            2: 4
        }
    ],
    [
        5, {
            1: 12,
            2: 6
        }
    ],
    [
        6, {
            1: 4,
            2: 10
        }
    ],
    [
        7, {
            1: 7,
            2: 1
        }
    ]
]
const PlanetPositionArray = [
    [
        [[2.05, 4]],
        [[2.5, 4.5], [1.8, 4.5]],
        [[2.5, 5], [1.8, 5], [2.1, 3.2]],
        [[2.4, 5.3], [1.8, 5.3], [2.4, 3.2], [1.8, 3.2]],
        [[1.7, 5.4], [2.6, 5.4], [2.05, 4.2], [1.7, 3.3], [2.6, 3.3]],
        [[1.7, 5.4], [1.7, 3.3], [2.6, 5.4], [2.6, 3.3], [2.05, 5.2], [2.05, 3.5]],
        [[1.7, 5.4], [1.7, 3.3], [2.6, 5.4], [2.6, 3.3], [2.05, 6.5], [2.05, 3.9], [2.05, 2.8]],
        [[1.7, 3.3], [1.7, 5.4], [2.6, 3.3], [2.6, 5.4], [2.05, 2.9], [2.05, 3.5], [2.05, 4.5], [2.05, 6.5]]
    ],
    [
        [[4, 12]],
        [[3.5, 17], [6, 17]],
        [[3.4, 22], [6, 22], [4.3, 7]],
        [[3.2, 30], [4.7, 30], [9, 30], [4.3, 7.5]],
        [[3.2, 35], [4.8, 35], [9.3, 35], [3.5, 9], [5.2, 8.8]],
        [[3.4, 40], [5, 40], [3.8, 12], [5.5, 12], [9, 40], [4.6, 7]],
        [[3.4, 40], [5, 40], [3.8, 12], [5.5, 12], [9, 40], [5, 7], [3.9, 7]],
        [[3.4, 40], [4, 40], [9, 40], [3.4, 12], [4.7, 12], [7.5, 12], [5, 7], [3.9, 7]]
    ],
    [
        [[12, 4.5]],
        [[18, 3.3], [18, 6]],
        [[8, 4.4], [22, 7.5], [22, 3.2]],
        [[25, 8], [25, 4.6], [25, 3.2], [7.5, 4.6]],
        [[9, 5.2], [28, 7], [9, 3.6], [28, 4.4], [28, 3.2]],
        [[9, 5.2], [28, 9], [9, 3.8], [28, 4.4], [28, 5.8], [28, 3.4]],
        [[9, 5.6], [28, 9], [9, 4.2], [28, 4.4], [28, 5.8], [28, 3.4], [9, 3.4]],
        [[9, 5.6], [9, 4.2], [9, 3.4], [28, 3.1], [28, 3.7], [28, 4.8], [28, 6.5], [28, 10]]
    ],
    [
        [[4.2, 2]],
        [[3, 2.1], [5.2, 2.1]],
        [[3.3, 2.2], [6, 2.2], [4.3, 1.8]],
        [[3.5, 2.25], [6, 2.25], [3.5, 1.75], [6, 1.75]],
        [[3.5, 2.4], [6, 2.4], [3.5, 1.7], [6, 1.7], [4.4, 1.98]],
        [[3.5, 2.4], [6, 2.4], [3.5, 1.7], [6, 1.7], [5.4, 2], [3.7, 2]],
        [[3.5, 2.4], [6, 2.4], [3.5, 1.7], [6, 1.7], [7, 2], [4.3, 2], [3.2, 2]],
        [[3.5, 2.4], [6, 2.4], [3.5, 1.7], [6, 1.7], [7, 2], [4.5, 2], [3.4, 2], [2.7, 2]]
    ],
    [
        [[12, 1.35]],
        [[18, 1.47], [18, 1.23]],
        [[8, 1.35], [22, 1.52], [22, 1.22]],
        [[25, 1.52], [25, 1.33], [25, 1.18], [7.5, 1.36]],
        [[9, 1.27], [28, 1.17], [9, 1.45], [28, 1.34], [28, 1.55]],
        [[9, 1.27], [28, 1.2], [9, 1.4], [28, 1.32], [28, 1.58], [28, 1.45]],
        [[28, 1.2], [28, 1.32], [28, 1.45], [28, 1.58], [9, 1.25], [9, 1.35], [9, 1.45]],
        [[28, 1.16], [28, 2.27], [28, 1.28], [28, 1.5], [28, 1.65], [9, 1.25], [9, 1.35], [9, 1.45]]
    ],
    [
        [[4.5, 1.12]],
        [[3.4, 1.1], [6, 1.1]],
        [[3.4, 1.08], [4.3, 1.2], [6, 1.08]],
        [[3.2, 1.08], [4.7, 1.08], [9, 1.08], [4.3, 1.22]],
        [[3.2, 1.07], [4.6, 1.07], [8, 1.07], [3.8, 1.17], [5.5, 1.17]],
        [[3.5, 1.06], [4.7, 1.06], [7.3, 1.06], [3.8, 1.15], [5.5, 1.15], [4.5, 1.25]],
        [[3.5, 1.05], [4.7, 1.05], [7.3, 1.05], [3.8, 1.12], [5.5, 1.12], [3.8, 1.20], [5.5, 1.20]],
        [[3.5, 1.05], [4.7, 1.05], [7.3, 1.05], [3.3, 1.12], [7, 1.12], [4.7, 1.12], [3.8, 1.20], [5.5, 1.20]]
    ],
    [
        [[2.05, 1.35]],
        [[2.5, 1.35], [1.8, 1.35]],
        [[2.1, 1.29], [2.5, 1.5], [1.8, 1.5]],
        [[2.3, 1.3], [2.3, 1.5], [1.8, 1.3], [1.8, 1.5]],
        [[2.4, 1.25], [2.4, 1.55], [1.8, 1.25], [1.8, 1.55], [2.05, 1.38]],
        [[2.4, 1.25], [2.4, 1.55], [1.8, 1.25], [1.8, 1.55], [1.9, 1.4], [2.2, 1.4]],
        [[2.4, 1.27], [1.8, 1.27], [2.1, 1.4], [1.7, 1.4], [2.6, 1.4], [2.4, 1.55], [1.8, 1.55]],
        [[2.4, 1.27], [1.9, 1.27], [1.7, 1.4], [2.1, 1.4], [2.7, 1.4], [2.4, 1.55], [1.9, 1.55], [2.1, 1.7]]
    ],
    [
        [[1.35, 1.13]],
        [[1.25, 1.09], [1.5, 1.09]],
        [[1.24, 1.08], [1.36, 1.2], [1.5, 1.08]],
        [[1.35, 1.22], [1.17, 1.08], [1.32, 1.08], [1.52, 1.08]],
        [[1.2, 1.07], [1.35, 1.07], [1.54, 1.07], [1.27, 1.17], [1.45, 1.17]],
        [[1.2, 1.06], [1.33, 1.06], [1.5, 1.06], [1.27, 1.15], [1.45, 1.15], [1.35, 1.25]],
        [[1.2, 1.05], [1.33, 1.05], [1.5, 1.05], [1.27, 1.12], [1.45, 1.12], [1.3, 1.20], [1.45, 1.20]],
        [[1.2, 1.05], [1.33, 1.05], [1.5, 1.05], [1.24, 1.12], [1.39, 1.12], [1.55, 1.12], [1.3, 1.2], [1.45, 1.2]]
    ],
    [
        [[1.13, 1.35]],
        [[1.1, 1.47], [1.1, 1.23]],
        [[1.18, 1.35], [1.08, 1.52], [1.08, 1.22]],
        [[1.08, 1.52], [1.08, 1.33], [1.08, 1.18], [1.22, 1.36]],
        [[1.17, 1.27], [1.07, 1.17], [1.17, 1.45], [1.07, 1.34], [1.07, 1.55]],
        [[1.2, 1.28], [1.1, 1.2], [1.2, 1.4], [1.1, 1.32], [1.1, 1.45], [1.1, 1.58]],
        [[1.2, 1.25], [1.2, 1.47], [1.2, 1.35], [1.1, 1.2], [1.1, 1.32], [1.1, 1.45], [1.1, 1.58]],
        [[1.2, 1.25], [1.2, 1.35], [1.2, 1.47], [1.1, 1.16], [1.1, 1.27], [1.1, 1.38], [1.1, 1.50], [1.1, 1.65]]
    ],
    [
        [[1.35, 2]],
        [[1.27, 2.1], [1.53, 2.1]],
        [[1.25, 2.2], [1.55, 2.2], [1.39, 1.8]],
        [[1.27, 2.25], [1.5, 2.25], [1.27, 1.75], [1.5, 1.75]],
        [[1.3, 2.4], [1.5, 2.4], [1.3, 1.7], [1.5, 1.7], [1.4, 2]],
        [[1.3, 2.4], [1.5, 2.4], [1.3, 1.7], [1.5, 1.7], [1.3, 2], [1.5, 2]],
        [[1.3, 2.4], [1.5, 2.4], [1.22, 2], [1.4, 2], [1.65, 2], [1.3, 1.7], [1.5, 1.7]],
        [[1.38, 2.9], [1.3, 2.4], [1.5, 2.4], [1.22, 2], [1.4, 2], [1.65, 2], [1.3, 1.7], [1.5, 1.7]],
    ],
    [
        [[1.13, 4.5]],
        [[1.1, 3.3], [1.1, 6]],
        [[1.18, 4.4], [1.08, 7.5], [1.08, 3.2]],
        [[1.08, 8.3], [1.08, 4.6], [1.08, 3.14], [1.22, 4.4]],
        [[1.18, 5.2], [1.17, 3.6], [1.07, 7.2], [1.07, 4.4], [1.07, 3.2]],
        [[1.18, 5.2], [1.18, 3.8], [1.08, 9], [1.08, 4.4], [1.08, 5.8], [1.08, 3.4]],
        [[1.18, 3.4], [1.18, 4.3], [1.18, 5.7], [1.08, 3.1], [1.08, 4], [1.08, 5.5], [1.08, 9]],
        [[1.2, 3.4], [1.2, 4.3], [1.2, 5.7], [1.1, 3], [1.1, 3.6], [1.1, 4.7], [1.1, 6.2], [1.1, 9]],
    ],
    [
        [[1.35, 12]],
        [[1.25, 17], [1.5, 17]],
        [[1.24, 22], [1.5, 22], [1.36, 7]],
        [[1.31, 30], [1.5, 30], [1.17, 30], [1.35, 7.5]],
        [[1.5, 40], [1.2, 40], [1.34, 40], [1.28, 10], [1.44, 10]],
        [[1.5, 40], [1.2, 40], [1.34, 40], [1.28, 12], [1.44, 12], [1.34, 7]],
        [[1.5, 40], [1.2, 40], [1.34, 40], [1.28, 12], [1.44, 12], [1.3, 7], [1.42, 7]],
        [[1.5, 40], [1.2, 40], [1.34, 40], [1.24, 12], [1.39, 12], [1.55, 12], [1.3, 7], [1.42, 7]],
    ]
]
const PlanetFriendShip = [
    [
        1, {
            1: [2, 4, 6], //friend
            2: [5, 7], //enemy
            3: [3] //neutral
        }
    ],
    [
        2, {
            1: [1, 3],
            2: [],
            3: [4, 6, 5, 7]
        }
    ],
    [
        3, {
            1: [1, 5],
            2: [2],
            3: [7, 4, 6]
        }
    ],
    [
        4, {
            1: [2, 1, 6],
            2: [3],
            3: [7, 5]
        }
    ],
    [
        5, {
            1: [3, 7],
            2: [1, 2],
            3: [4, 6]
        }
    ],
    [
        6, {
            1: [2, 4, 1],
            2: [3, 5],
            3: [7]
        }
    ],
    [
        7, {
            1: [3, 5],
            2: [1, 2, 4],
            3: [6]
        }
    ]
]
const AsthakvargaStrengthTable = [
    {
        "id": 1, "value": [
            { "id": 1, "value": [1, 2, 4, 7, 8, 9, 10, 11] },
            { "id": 2, "value": [3, 6, 10, 11] },
            { "id": 3, "value": [3, 5, 6, 9, 10, 11, 12] },
            { "id": 4, "value": [1, 2, 4, 7, 8, 9, 10, 11] },
            { "id": 5, "value": [6, 7, 12] },
            { "id": 6, "value": [5, 6, 9, 11] },
            { "id": 7, "value": [1, 2, 4, 7, 8, 9, 10, 11] },
            { "id": 8, "value": [3, 4, 6, 10, 11, 12] },
        ]
    }, {
        "id": 2, "total": 49, "value": [
            { "id": 1, "value": [3, 6, 7, 8, 10, 11] },
            { "id": 2, "value": [1, 3, 6, 7, 10, 11] },
            { "id": 3, "value": [1, 3, 4, 5, 7, 8, 10, 11] }, ///review
            { "id": 4, "value": [2, 3, 5, 6, 9, 10, 11] },
            { "id": 5, "value": [3, 4, 5, 7, 9, 10, 11] },
            { "id": 6, "value": [1, 4, 7, 8, 10, 11, 12] },
            { "id": 7, "value": [3, 5, 6, 11] },
            { "id": 8, "value": [3, 6, 10, 11] }
        ]
    },
    {
        "id": 3, "total": 54, "value": [
            { "id": 1, "value": [5, 6, 9, 11, 12] },
            { "id": 2, "value": [2, 4, 6, 8, 10, 11] },
            { "id": 3, "value": [1, 3, 5, 6, 9, 10, 11, 12] },
            { "id": 4, "value": [1, 2, 4, 7, 8, 9, 10, 11] },
            { "id": 5, "value": [1, 2, 3, 4, 5, 8, 9, 11] },
            { "id": 6, "value": [6, 8, 11, 12] },
            { "id": 7, "value": [1, 2, 4, 7, 8, 9, 10, 11] },
            { "id": 8, "value": [1, 2, 4, 6, 8, 10, 11] },
        ]
    },
    {
        "id": 4, "total": 39, "value": [
            { "id": 1, "value": [3, 5, 6, 10, 11] },
            { "id": 2, "value": [3, 6, 11] },
            { "id": 3, "value": [3, 5, 6, 11] },
            { "id": 4, "value": [1, 2, 4, 7, 8, 10, 11] },
            { "id": 5, "value": [6, 8, 11, 12] },
            { "id": 6, "value": [6, 10, 11, 12] },
            { "id": 7, "value": [1, 4, 7, 8, 9, 10, 11] },
            { "id": 8, "value": [1, 3, 6, 10, 11] },
        ]
    },
    {
        "id": 5, "total": 52, "value": [
            { "id": 1, "value": [8, 11, 12] },
            { "id": 2, "value": [1, 2, 3, 4, 5, 8, 9, 11, 12] },
            { "id": 3, "value": [3, 5, 6, 9, 11] },
            { "id": 4, "value": [3, 5, 6, 9, 11, 12] },
            { "id": 5, "value": [1, 2, 3, 4, 5, 8, 9, 10, 11] },
            { "id": 6, "value": [5, 8, 9, 10, 11] },
            { "id": 7, "value": [3, 4, 5, 8, 9, 10, 11] },
            { "id": 8, "value": [1, 2, 3, 4, 5, 8, 9, 11] }
        ]
    },
    {
        "id": 6, "total": 56, "value": [
            { "id": 1, "value": [1, 2, 3, 4, 7, 8, 9, 10, 11] },
            { "id": 2, "value": [2, 5, 7, 9, 11] },
            { "id": 3, "value": [1, 2, 4, 5, 6, 9, 10, 11] },
            { "id": 4, "value": [1, 2, 4, 7, 8, 10, 11] },
            { "id": 5, "value": [2, 5, 6, 9, 10, 11] },
            { "id": 6, "value": [1, 2, 3, 4, 7, 8, 10, 11] },
            { "id": 7, "value": [3, 5, 6, 12] },
            { "id": 8, "value": [1, 2, 4, 5, 6, 7, 9, 10, 11] }
        ]
    },
    {
        "id": 7, "value": [
            { "id": 1, "value": [1, 2, 4, 7, 8, 10, 11] }, //need review
            { "id": 2, "value": [3, 6, 11] },
            { "id": 3, "value": [6, 8, 9, 10, 11, 12] },
            { "id": 4, "value": [3, 5, 6, 10, 11, 12] },
            { "id": 5, "value": [6, 11, 12] },
            { "id": 6, "value": [5, 6, 11, 12] },
            { "id": 7, "value": [3, 5, 6, 11] },
            { "id": 8, "value": [1, 3, 4, 6, 10, 11] }
        ]
    },
    {
        "id": 8, "total": 49, "value": [
            { "id": 1, "value": [3, 4, 6, 10, 11, 12] }, //need review
            { "id": 2, "value": [3, 6, 10, 11] },
            { "id": 3, "value": [1, 2, 4, 6, 8, 10, 11] },
            { "id": 4, "value": [1, 3, 6, 10, 11] },
            { "id": 5, "value": [1, 2, 3, 4, 5, 8, 9, 11] },
            { "id": 6, "value": [1, 2, 4, 5, 6, 7, 9, 10, 11] },
            { "id": 7, "value": [1, 3, 4, 6, 10, 11] },
            { "id": 8, "value": [3, 6, 10, 11] }
        ]
    }
]
const GetYearMonthDay = date => {
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    return year + "/" + month + "/" + day;
}
const randomDate = (start, end) => {
    var d = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}
const randomTime = () => {
    function pad(number) {
        //Add a leading 0 if the number is less than 10
        return ((number < 10) && "0") + number.toString();
    }
    var r = Math.floor(Math.random() * 1440);
    var HH = pad(1 + (Math.floor(r / 60) % 12));
    var MM = pad(r % 60);
    var m = HH + ":" + MM + ":00";
    return m;
}
const myNameArray = ["Aaran", "Aaren", "Aarez", "Aarman", "Manish", "Anmol", "Suren", "Chiran", "Subrat", "Rahaman", "Omar"];
const myLNameArray = ["Kuikel", "Shrestha", "Baral", "Hamal"];
const eventConfirmationTypes = [
    { "id": 1, "value": "Exact" },
    { "id": 2, "value": "Approximate" },
    { "id": 3, "value": "Not confirmed" }
]
export {
    PlanetExaltDeblitation, NepaliMonthList, myNameArray, myLNameArray, ZodiacPlanetOwnerMap, PlanetPositionArray, PlanetAspectTable,
    ListOfTimeZone, PlanetFriendShip, randomDate, randomTime, GetYearMonthDay, ZodiacList, NakshatraPadList, AsthakvargaStrengthTable,
    AccountStatusList, AccountTypeList, UpdateReason, Loading, eventConfirmationTypes, BatchStatus, CourseStatus, StudentStatus,
    QuestionTypes, QuestionSetStatus, PriceList, PaymentTypes
}
