import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import StartApp from './StartApp';
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { GlobalProvider } from './context/GlobalContext';
ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <StartApp />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
