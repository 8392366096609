import React, { useContext, lazy, Suspense, useEffect, useState } from 'react';
import { GlobalContext } from './context/GlobalContext';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Loading } from './context/FetchData';
import txt from './textFiles/maintenance.txt';
import './Admin.css';
const Home = lazy(() => import('./pages/Home'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Astrologers = lazy(() => import('./pages/Astrologers'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const PublicPage = lazy(() => import('./pages/PublicPage'));
const AppointmentPublic = lazy(() => import('./pages/AppointmentPublic'));
const PageRouter = lazy(() => import('./components/PageRouter'));
const Folders = lazy(() => import('./components/Folders'));
const AdminRoute = lazy(() => import('./components/AdminRoute'));

const StartApp = () => {
  const [all, setAll] = useState({ show: false, text: "" });
  const Footer = () => <div className="row">
    <div className="col-12 p-0">
      <div className="card-body bg-light mt-3">Copyright © 2020 Kuikel Astrology. Privacy Policy | Terms and Conditions</div>
    </div>
  </div>

  const MaintenanceAlert = () => all.show ? <div className="row">
    <div className="col-12 p-0">
      <div className="alert alert-warning alert-dismissible fade show w-100 m-0 my-1" role="alert">
        <strong>WEBSITE MAINTENANCE !</strong> {all.text}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div> : <></>

  useEffect(() => {
    if (!all.show) fetch(txt, { cache: 'no-store' }).then(r => r.text()).then(t => {
      if (t !== "") setAll({ ...all, text: t, show: true })
    })
  }, [])
  return <>
    <MaintenanceAlert />
    <Router>
      <Switch>
        <Suspense fallback={Loading()} >
          <Route exact path="/" component={Home} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/professional-astrologers" component={Astrologers} />
          <Route exact path="/appointment/" component={AppointmentPublic} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/public/:pageId/" component={PublicPage} />
          <PrivateRoute exact path="/f/folders/:p1?/:p2?/:p3?/:p4?/:p5?/:p6?/" component={Folders} />
          <PrivateRoute exact path="/admin/:level1/:level2?/:level3?/:level4?/" component={PageRouter} />
          <PrivateRoute exact path="/chart/:level1/:level2/" component={AdminRoute} />
          <PrivateRoute exact path="/chart/:level1/:level2/:level3/" component={AdminRoute} />
        </Suspense>
      </Switch>
    </Router>
    <Footer />
  </>
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { GS } = useContext(GlobalContext);
  return (<Route {...rest} render={props => GS.token ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />} />);
}


export default StartApp;
